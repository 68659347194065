input {
	&[name="item-list"] {
		position: absolute;

		visibility: hidden;
	}
}

#item-list-summary:checked ~ .import-list.summary,
#item-list-details:checked ~ .import-list.details,
#item-list-issues:checked ~ .import-list.issues {
	display: block;
}

.import-list {
	display: none;

	&.summary {}

	&.details {}

	&.issues {}
}

.photo-peek {
  width: 200px;
  height: 100px;

  order: 3;

  position: relative;

  overflow: hidden;

  img {
    max-width: none;

    position: absolute;
    right: 0;
    bottom: 0;
  }
}
