input[type=file] {
	max-width: 100%;
	width: 100%;
	height: 2.5em;
	padding-left: 0.75em;
	padding-right: 0.75em;

	display: inline-flex;

	position: relative;
	-webkit-appearance: none;
	-moz-appearance: none;
	-webkit-box-sizing: border-box;

	color: $dark;
	font-size: 1rem;
	line-height: 2.5em;
	vertical-align: top;

	background-color: #FFF;

	border: 1px solid $light;
	border-radius: 3px;

	box-shadow: inset 0 1px 2px rgba(10,10,10,0.1);

	align-items: center;
	justify-content: flex-start;

	&::-webkit-file-upload-button {
		width: 0;
		height: 100%;
		margin: 0;
		padding: 0;

		-webkit-appearance: none;

		border: none;
	}
}

x::-webkit-file-upload-button,
input[type=file]:after {
	width: auto;
	height: 2.5em;
	margin-right: -1px;
	padding-left: 0.75em;
	padding-right: 0.75em;

	display: inline-flex;
	-moz-appearance: none;
	-webkit-appearance: none;

	position: absolute;
	top: -1px;
	right: 0;

	color: $dark;
	font-size: 1rem;
	text-align: center;
	line-height: 1.5;
	vertical-align: top;
	white-space: nowrap;

	background-color: #FFF;

	border: 1px solid $light;
	border-radius: 0 3px 3px 0;

	box-shadow: none;

	align-items: center;
	justify-content: center;

	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	cursor: pointer;

	content: "Select";
}