ul.tree {
	margin-left: 0;

	list-style: none;

	border: 0 #DBDBDB solid;

	ul,
	li {
		border-color: inherit;
	}

	ul {
		margin-left: 0;
		padding-left: 32px;

		list-style: none;

		&::before {
			display: block;

			position: absolute;
			top: 25px;
			bottom: 11px;
			left: 16px;

			border-left-width: 1px;
			border-left-color: inherit;
			border-left-style: solid;

			content: "";

			z-index: 1;
		}
	}

	li {
		margin-top: 5px;

		position: relative;

		white-space: nowrap;

		z-index: 3;

		&::after {
			width: 16px;
			height: 1px;
			margin-top: -1px;

			display: block;

			position: absolute;
			top: 10px;
			left: -16px;

			border-width: 0 0 1px 1px;
			border-color: inherit;
			border-style: solid;

			content: "";

			z-index: 1;
		}

		&:last-child::before {
			bottom: 50%;
		}

		a {
			margin-left: 10px;
		}

		.button {
			margin-left: 0;

			text-align: left;
			line-height: 32px;

			&.is-danger {
				margin-left: 5px;

				float: right;
			}
		}
	}

	> li::after {
		display: none;
	}
}