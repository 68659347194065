@import "variables";

@import "utilities/mixins";

@import "components/admin/inputs";
@import "components/admin/treelist";

@import "pages/admin/imports";

tr {
	&.rummage-nomatch {
		display: none;
	}
}

img {
	display: block;
}