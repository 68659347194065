$primary: #3F51B5;
$info: #2196F3;
$success: #4CAF50;
$warning: #FFC107;
$danger: #FF5722;
$light: #DBDBDB;
$text: #4A4A4A;
$dark: #363636;
$tablet: 640px;
$desktop: 1000px;
$widescreen: 1280px;
$family-primary: "Roboto", sans-serif;
$family-secondary: "Roboto Condensed", sans-serif;
$title-weight: 500;